.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  margin-left: auto;
  margin-right: auto;
  min-height: 83vh;
  padding-bottom: 30px;
}

.topName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      border-radius: 50%;
    }
    h1 {
      color: var(--gray-1, #333);
      font-weight: 800;
      font-size: 16px;
    }
    color: var(--gray-3, #828282);
    font-size: 20px;
    span {
      font-size: 14px;
    }
  }
  font-weight: 500;
}
.subHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header {
    margin-top: 30px;
    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--mobile-primary-color-brown, #b17f34);
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
      color: var(--Gray-2, #4f4f4f);
    }
  }

  .date {
    border-right: 1px solid #e8e8e8;

    h1 {
      font-size: 16px;
      font-weight: 700;
      line-height: 27px; /* 150% */
      color: var(--Gray-1, #333);
    }
    .editAndDownload {
      display: flex;
      .edit {
        margin-right: 20px;
      }
      .edit,
      .download {
        display: flex;
        cursor: pointer;
        img {
          margin: 0 14px;
        }
        span {
          font-size: 14px;
          font-weight: 900;
          color: var(--Dark-Brown, #6a451a);
        }
      }
    }
  }
}

.notes {
  max-height: 842px;
  min-height: 300px;
  overflow: auto;
  width: 1044px;
  background: #fff;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  .noEditor {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  h1 {
    color: var(--Gray-1, #333);
    font-size: 20px;
    line-height: 27px; /* 135% */
    p {
      text-align: center;
    }
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: var(--Gray-2, #4f4f4f);
  }
  button {
    width: 200px;
  }
  .editor {
    width: inherit;
    height: inherit;
  }
}

.editorButtons {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
  width: 100%;

  .editorBtnWrapper {
    display: flex;
  }
  .cancelBtn {
    button {
      background: transparent;
      color: #333;
      text-align: center;
      font-size: 16px;
      font-weight: 900;
      width: 174px;
      transition: 1s ease-in-out;
      margin-right: 20px;
      color: var(--Dark-Brown, #6a451a);
    }
    button:hover {
      border: 1px solid var(--Dark-Brown, #6a451a);
    }
  }
  .saveBtn {
    width: 100%;
    button {
      width: 174px;
    }
  }
}
.noEditor {
  width: 100%;
  height: 100%;
}

.editedText {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.completeButton {
  display: flex;
  flex-direction: row-reverse;
  button {
    width: 150px;
  }
  button:hover {
    opacity: 0.7;
  }
}
.ButtonWrapper {
  button {
    padding: 10px 0px;
  }
}

.carePlanSuccessModal {
  height: 248px;
  flex-shrink: 0;
  width: 564px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    margin-top: 24px;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 16px;
    color: var(--Gray-1, #333);
  }
  p {
    font-size: 16px;
    width: 80%;
    margin-bottom: 21px;
    text-align: center;
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: var(--Gray-2, #4f4f4f);
  }
}
