.dashboard-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  font-family: 'Avenir';

  .dashboard-profile-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      width: 21%;
      height: 21%;
      border-radius: 50px;
      margin-right: 13px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--gray-3, #828282);
    }

    h1 {
      font-size: 18px;
      font-weight: 800;
      color: var(--gray-1, #333);
    }
  }

  .dashboard-profile-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 24px;
      height: 24px;
      margin-right: 14px;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      font-family: 'Avenir';
    }

    &-invite-disabled {
      background: var(--gray-4, #bdbdbd);
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      cursor: not-allowed;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      height: 48px;
      width: 162px;
      align-items: center;
      justify-content: center;
    }

    &-invite-allowed {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .dashboard-profile-plan {
        background: #fd9c42;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
        display: flex;
        flex-direction: row;
        height: 48px;
        cursor: pointer;
        width: 188px;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
      }

      .dashboard-profile-invite {
        width: 162px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border: none;
        height: 48px;
        background: #fd9c42;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
        border-radius: 6px;
      }
    }
  }
}