.floating-input {
  position: relative;
  width: 100%;

  input,
  textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    color: #575757;
  }

  textarea {
    height: 250px;
  }

  label {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 14px;
    color: #999;
    background-color: #fff;
    pointer-events: none;
    transition: all 0.3s;
    font-weight: 500;
    font-family: 'Avenir';
  }


}

.error {
  input {
    border-color: red;
  }
}