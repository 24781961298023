.root {
  height: 165px;

  cursor: pointer;
  width: 251px;
  padding: 20px;
  background: var(--gray-6, #f2f2f2);
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  transition: 0.4s;

  .head {
    margin-bottom: 8px;

    h1 {
      color: var(--gray-1, #333);
      font-size: 18px;
      font-weight: 800;

    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: var(--gray-2, #4f4f4f);
  }
}

.root:hover {
  border-color: #fd9c42;

}

.root.active {
  border-color: #fd9c42;
  border: 4px solid #fd9c42;
}

.disabled {
  cursor: not-allowed;
  background: var(--gray-6, #f2f2f2);
  border-color: #f2f2f2;

  .head {
    h1 {
      color: var(--gray-3, #828282);
    }
  }

  p {
    color: var(--gray-3, #828282);
  }
}