.sidebar_wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  color: #828282;
  gap: 1.5rem;
  width: 186px;
  flex-shrink: 0;
}

.item {
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 1rem;
  text-align: left;

  width: 50%;
  &:active,
  &:focus {
    border-right: #b17f34 solid 5px;
  }
  background: transparent;
  border: none;
}

.profile_item {
  display: flex;
  width: 136px;
  height: 44px;
  padding: 9px 0px 8px 13px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
  cursor: pointer;

  &:focus {
    border-right: #b17f34 solid 5px;
  }
  background: transparent;
  border: none;
}
.active {
  border-right: #b17f34 solid 5px;
}
