.page-container {
}

.main-content-container {
  background-color: #ffffff;
  width: calc(100% - 25rem);
  height: 100%;
  font-family: 'Avenir';
  font-size: 20px;
  gap: 2rem;
  padding: 50px;
  margin-left: 25rem;
  position: relative;
}
header {
  padding: 0 50px;
  height: 70px;
  background: #e0e0e0;
  color: #333333;
  // position: fixed;
  // top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-family: 'Avenir';
}

.sidebar-logo {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav {
  width: 25rem;
  background: #e0e0e0;

  height: 100%;
  padding: 50px 30px 0;
  transition: all 1s;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}
ul {
  padding: 0;
}
ul li {
  list-style-type: none;
}

ul li a {
  text-decoration: underline;
  font-size: 1.2rem;
  color: #000000;
  display: block;
  margin-bottom: 2rem;
}
ul li a:hover,
ul li a:active {
  color: #6a451a;
  text-decoration: none;
}

.back-button {
  // position: sticky;
  // top: 0;
  padding: 15px;
  background-color: #fd9c42;
  border: none;
  border-radius: 5px;
  width: 10%;
}
