.payment_container {
  display: flex;
  flex-direction: column;
  color: #828282;
  justify-content: flex-start;
  align-items: center;
  padding: 21px;
  width: 100%;
  height: 100%;
}

.payment_header {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  color: #828282;
  width: 100%;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  padding: 21px;
  gap: 1.5rem;
}

.payment_items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  background-color: #f5f5f5;
  width: 100%;
}

.btn {
  padding: 10px;
  font-size: 20px;
  font-weight: 200;
  background-color: #f8933bfd;
  color: #fff;
  border-radius: 5px;
  width: 20%;
  cursor: pointer;
  box-shadow: none;
  border: none;
}
.btn:hover {
  background: #f3790dfd;
}
.payment_items_h1 {
  color: #635bff;
}
.modal_button {
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  outline: none;
  border: none;
  color: #fff;
  transition: 0.3s ease-in;
  font-family: 'Avenir';
  cursor: pointer;
  width: 100%;
  background-color: #fd9c42;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
}
.modal_h2 {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #333;
  margin-bottom: 7px;
}
.modal_p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 31px;
}
.payment_modal {
  background-color: #fff;
  padding: 55px 40px 48px;
  border-radius: 5px;
  width: 23.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.modal-Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  width: 364px;
  justify-content: center;
  border-radius: 5px;
  h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 25px;
    margin-bottom: 16px;
    color: var(--gray-1, #333);
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-2, #4f4f4f);
    line-height: 24px; /* 150% */
    margin-bottom: 47px;
  }
}
.connected{
  color: #ffffff;
  background-color: #fd9c42;
  cursor: not-allowed;
}

.connected:hover {
 
  background-color: gray; 
  opacity: 0.8; 
}