.root {
  max-height: 647px;
  width: 794px;
  padding: 39px 65px;
}

.medicationContainer {
  margin-top: 40px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.medicationContainer::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

.medicationContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Change the color as needed */
  border-radius: 4px;
}

.medicationContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.medication {
  display: flex;
  position: relative;
  margin-bottom: 44px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  span {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
  input {
    height: 52px;
    width: 100%;
    border: none;
    background: var(--gray-6, #f2f2f2);
    padding: 0 10px;
    font-size: 14px;
    border-radius: 5px;
  }
  input:focus {
    outline: none;
  }
}

.delete {
  right: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 900;
  color: #eb5757;
  top: 0;
}

.header {
  h1 {
    font-size: 20px;
    font-weight: 900;
    color: var(--gray-1, #333);
  }
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}

.done {
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: space-between;
  button {
    color: #fff;
    background-color: #fd9c42;
    width: 196px;
    height: 55px;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    margin-top: 52px;
  }
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
  cursor: pointer;
  img {
    margin-right: 4px;
  }
  span {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}

.label {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}
