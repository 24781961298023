.root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.left {
    height: 100%;
    flex-shrink: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    border-radius: 8px;
    overflow: auto;
    padding: 30px;

    .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            margin-right: 5px;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 0px;

        span {
            font-size: 14px;
            font-weight: 900;
            color: #6a451a;
            cursor: pointer;
        }

        h1 {
            font-size: 16px;
            font-weight: 900;
            color: var(--gray-1, #333);
        }

    }


}