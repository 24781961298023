.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1048px;
  margin-left: auto;
  margin-right: auto;
  padding: 21px;
  .backButton {
    width: 137px;
    height: 41px;
    cursor: pointer;
    border-radius: 8px;
    border: none;
    background: var(--mobile-primary-color-default-color, #f4e5d8);
    transition: 0.3s;
    font-size: 16px;
    margin-bottom: 43px;
    font-weight: 500;
    color: var(--dark-brown, #6a451a);
  }
  .backButton:hover {
    background: #f1ae74fd;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h1 {
      font-size: 20px;
      font-weight: 900;
      line-height: 28px;
      color: var(--gray-1, #333);
    }
    .downloadButton {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      span {
        color: var(--dark-brown, #6a451a);
        font-size: 14px;
        font-weight: 900;
      }
      img {
        width: 18px;
        height: 18px;
        margin-left: 12px;
      }
    }
  }
}

.content {
  background-color: #fff;
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  .left {
    width: 30%;
    padding: 52px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 4px;
    }
    h1 {
      font-size: 18px;
      font-weight: 900;
      line-height: 32px;
      color: #181725;
      margin-top: 5px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-1, #333);
    }
  }
  .right {
    border-left: 1px solid #dbdce9;
    width: 70%;
    padding: 50px;
    h1 {
      font-size: 18px;
      font-weight: 900;
      line-height: 32px;
      color: #181725;
      margin-bottom: 22px;
    }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-1, #333);
      margin-bottom: 24px;
    }
  }
}
