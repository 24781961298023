.country-select-container {
  // overflow: hidden !important;
  max-height: 100px !important;



  select {
    background: #f2f2f2;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 5px;
    margin-right: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 16px;
    max-width: 100px !important;
    max-height: 100px !important;
    // overflow: scroll !important;
    background-color: red !important;

    option {
      // display: none;
      height: 50px;
      background-color: green !important;
    }
  }
}