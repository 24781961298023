.time-scroll-container {
  max-height: 500px;
  overflow-y: auto;
  width: 100;
  border: 1px solid #ecf5ff;
  background: #fff;
  box-shadow: 0px 6px 16px 0px rgba(61, 75, 95, 0.08);
  border-radius: 5px;
  height: 332px;
  width: 304px;
}

.time-scroll-container::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

.time-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Change the color as needed */
  border-radius: 4px;
}

.time-scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.time-item {
  font-size: 15px;
  background-color: #f2f2f2;
  height: 40px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 10px;
  color: #4f4f4f;
  cursor: pointer;
}

.time-item.active {
  background-color: #6a451a;
  color: white;
}
