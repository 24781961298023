.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  height: 85vh;
  margin-left: auto;
  margin-right: auto;
}

.topName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .image {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      border-radius: 50%;
    }

    h1 {
      color: var(--gray-1, #333);
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 2px;
    }

    span {
      color: var(--gray-3, #828282);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.container {
  margin-top: 15px;

  .header {
    margin-bottom: 27px;

    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--mobile-primary-color-brown, #b17f34);
    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      color: var(--gray-2, #4f4f4f);
    }
  }
}

.contents {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .left,
  .right {
    background: #fff;
    width: 47%;
    height: 425px;
    padding: 10px 30px;

    .leftHeader,
    .rightHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;

      h1 {
        text-align: center;
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 8px;
        color: var(--gray-1, #333);
      }

      p {
        text-align: center;
        padding-top: 4px;
        font-size: 14px;
        font-weight: 500;
        color: var(--dark-brown, #6a451a);
        background-color: #fff0e3;
        height: 29px;
        flex-shrink: 0;
        width: 206px;
      }
    }
  }
}

.bulkBill {
  background: #fff;
  width: 47%;
  height: 444px;
  padding: 31px 47px;
  margin-left: 250px;

  .bulkBillHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;

    h1 {
      text-align: center;
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 8px;
      color: var(--gray-1, #333);
    }

    p {
      text-align: center;
      padding-top: 4px;
      font-size: 14px;
      font-weight: 500;
      color: var(--dark-brown, #6a451a);
      background-color: #fff0e3;
      height: 29px;
      flex-shrink: 0;
      width: 206px;
    }
  }
}

.bulkBillButton {
  float: right;
  width: 288px;
  transition: 0.3s ease-in-out;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  background: var(--mobile-primary-color-default-color, #fd9c42);
  margin-right: 400px;
  margin-top: 10px;
  height: 55px;
  width: 250px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}

.information {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .info:nth-child(odd) {
    width: 45%;
  }

  .info:nth-child(even) {
    width: 45%;
  }
}

.info {
  margin-bottom: 32px;

  span {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--gray-2, #4f4f4f);
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-1, #333);
  }
}

.viewMore {
  align-self: center;
  text-align: center;
  font-size: 15px;
  font-weight: 900;
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--dark-brown, #6a451a);
}

.summary {
  margin-bottom: 10px;

  p {
    font-size: 14px;
    font-weight: 500;
    color: var(--gray-3, #828282);
    margin-bottom: 8px;
  }

  div {
    width: 100%;
    background: var(--gray-6, #f2f2f2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 82px;

    span {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: var(--gray-2, #4f4f4f);
    }

    h1 {
      text-align: center;
      font-size: 22px;
      font-weight: 900;
      margin-top: 10px;
      line-height: 24px;
      /* 109.091% */
      color: var(--gray-1, #333);
    }
  }
}

.done {
  float: right;
  width: 288px;
  transition: 0.3s ease-in-out;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  background: var(--mobile-primary-color-default-color, #fd9c42);

  margin-top: 10px;
  height: 55px;
  width: 250px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}

.done:hover {
  opacity: 0.7;
}

.modalContainer {
  width: 824px;
  height: 700px;

  .modalHeader {
    padding: 35px 0 0 64px;
    margin-bottom: 28px;

    h1 {
      font-size: 22px;
      font-weight: 900;
      color: var(--Dark-Brown, #6a451a);
    }

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      color: var(--Gray-2, #4f4f4f);
    }
  }

  .modalContent {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #e0e0e0;
    width: 100%;
    height: 100%;

    .middle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
    }

    .modalLeft {
      padding: 25px 0 0 64px;
      width: 40%;
      height: 80%;
      border-right: 1px solid #e0e0e0;
    }

    .modalRight {
      padding: 25px 64px;

      .rightHeader {
        h1 {
          font-size: 16px;
          font-weight: 900;
          color: var(--Gray-1, #333);
        }

        p {
          color: var(--mobile-primary-color-brown, #b17f34);
          text-align: center;
          font-size: 10px;
          font-weight: 900;
          padding: 6px;
          margin-top: 7px;
          background-color: #fff0e3;
          width: max-content;
          text-transform: uppercase;
        }
      }

      .rightItems {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 80%;
      }

      .messageNote {
        span {
          font-size: 14px;
          font-weight: 500;
          color: var(--Gray-2, #4f4f4f);
        }

        div {
          border: 1px solid var(--Gray-6, #f2f2f2);
          background: #f6f6f6;
          border-radius: 4px;
          height: 87px;
          flex-shrink: 0;
          width: 374px;
        }
      }

      .modalButtons {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 10px;
        cursor: pointer;

        button:first-child {
          background: var(--mobile-primary-color-cream-yellow, #f8eedf);
          border-radius: 6px;
          height: 48px;
          width: 196.329px;
          text-align: center;
          font-size: 15px;
          font-weight: 900;
          transition: 0.3s ease-in-out;
          margin-right: 20px;
          color: var(--Dark-Brown, #6a451a);
        }

        button:last-child {
          background: var(--mobile-primary-color-default-color, #fd9c42);
          border-radius: 6px;
          transition: 0.3s ease-in-out;
          height: 48px;
          width: 164.845px;
        }

        button:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.claimModal {
  height: 348px;
  flex-shrink: 0;
  width: 364px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    margin-top: 24px;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 16px;
    color: var(--Gray-1, #333);
  }

  p {
    font-size: 16px;
    width: 80%;
    margin-bottom: 21px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    color: var(--Gray-2, #4f4f4f);
  }
}

.btn_done {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  background: var(--mobile-primary-color-default-color, #fd9c42);
  height: 55px;
  width: 100px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}

.db4 {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  background: #000000;
  height: 55px;
  width: 100px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 10px;
}