.profile_wrapper {
  color: #828282;
  padding: 21px 50px;
  width: 100%;
  height: 100%;
}

.profile_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.img_container {
  img {
    width: 100px;
    height: 100px;
    border-radius: 120px;
  }
}

.personal_details {
  display: flex;
  width: 480px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Gray-1, #333333);
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 45.5px;
  margin-left: 90px;
}

.profile_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.picture_btn {
  border: none;
  color: #f37a0f;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.input-style {
  display: block;
  padding: 20px;

  outline: none;
  border: #cccccc solid 1px;
  border-radius: 5px;

  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #cccccc;
  width: 370px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f8f8f8;
}

.bio_item {
  margin-left: 15px;

  h1 {
    font-size: 16px;
    font-weight: 900;
    color: #333333;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: #4F4F4F;
    ;
  }

}

.input-column {
  margin-top: 20px;
  width: 40%;
}

.input-row {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rowWrapper {
    width: 45%;

  }
}

.input-column-register {
  margin-top: 20px;
  width: 40%;
  margin-right: 390px;
}

.custom_input_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.educationSection {
  font-family: 'Avenir';

  .educationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .plusContainer {
    display: flex;
    align-items: center;

    width: 10%;

    span {
      color: #FD9C42;
      margin-left: 5px;
    }
  }

  .educationList {
    width: 100%;
    display: flex;
    margin: 5px 0px;
  }

  .educationItem {
    width: 90%;
  }

  width: 100%;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  padding: 10px;

  h3 {
    font-size: 16px;
    color: #4F4F4F;
    font-weight: 400;
    margin-bottom: 15px;

  }

  span {
    font-size: 14px;
    color: #4F4F4F;
    font-weight: 400;
  }

  .educationItemActions {
    width: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .pencilContainer,
    .trashContainer {
      background-color: #FEEFE2;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }

    .trashContainer {
      background-color: #F8F8F8;
    }
  }
}

.eduModalContainerWrapper {
  width: 40%;
  // overflow: hidden;
  padding: 30px;
}

.eduModalWrapper {
  width: 100%;
}

.eduModalBtn {
  button {
    margin-top: 20px;
    background-color: #FFF6EE;
    color: #FD9C42;
  }
}

.eduDatePicker {
  width: 100%;
}

.warnModalBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .warnBtnInnerContainer {
    display: flex;

    .cancelBtnContainer {
      margin-right: 10px;
    }

    .deleteBtnContainer {
      button {
        background-color: #ED5E68;
        color: white;

      }
    }
  }

}


.datePickerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 45%;

  label {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 14px;
    color: #999;
    background-color: #fff;
    pointer-events: none;
    transition: all 0.3s;
    font-weight: 500;
    z-index: 5;
  }

}

.btnContainer {
  text-align: end;
  margin-top: 20px;
  width: 100%;


  button {
    margin-bottom: 24px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 900;
    cursor: pointer;
    transition: 0.4s;
    text-align: center;
    width: 15%;
    padding: 15px 0px;
  }

}

.uploadWrapper {

  height: auto;
  width: 300px;
  background-color: #F5F5F5;


  h1 {
    font-size: 14px;
  }

  p,
  span {
    font-size: 13px;
    color: #4F4F4F;

  }

  .dragContent {
    .selectBtnWrapper {
      width: 100%;

      button {
        width: 40%;
        text-align: center;
        margin-top: 30px;
      }

    }
  }

}

.uploadBtnWrapper {
  margin-top: 20px;
  width: 100%;
  text-align: center;

  button {
    width: 50%;
    text-align: center;
    margin-top: 30px;
  }
}

.containerWrapper {
  background-color: #F5F5F5;
  overflow: hidden;
}





:global {

  .ant-modal {
    margin-top: 100px;
  }



  .ant-upload,
  .ant-upload-btn {
    border: none !important;
    background-color: white !important;
    border-radius: 20px !important;
    height: 150px !important;
    margin-top: 10px !important;
  }

  .ant-tabs-nav {
    color: #949494 !important;
    width: 250px;
    font-weight: 500
  }

  .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FD9C42 !important;

  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #FF7B00;
  }

  .ant-tabs-ink-bar {
    color: #FD9C42 !important;
    border-bottom: 2px solid #FD9C42 !important;
  }

}