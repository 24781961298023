.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;

  height: 81vh;
  margin-left: auto;
  margin-right: auto;
}

.topName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .image {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      color: var(--gray-1, #333);
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 2px;
    }
    span {
      color: var(--gray-3, #828282);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  height: 320px;
  flex-shrink: 0;
  padding: 32px;
  width: 396px;
  background: #fff;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  border-radius: 8px;
  h1 {
    font-size: 20px;
    font-weight: 900;
    color: var(--gray-1, #333);
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: var(--gray-2, #4f4f4f);
    margin-bottom: 36px;
  }

  form {
    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      color: var(--gray-2, #4f4f4f);
    }
    .amount {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 56px;
      flex-shrink: 0;
      width: 320px;
      padding: 10px 5px;
      background: var(--gray-6, #f2f2f2);
      border-radius: 5px;
      margin-bottom: 10px;
      div {
        height: 48px;
        flex-shrink: 0;
        padding: 12px 8px;
        background: #e8e3db;
        border-radius: 5px;
        width: 53.018px;
        span {
          font-size: 16px;
          font-weight: 900;
          line-height: 24px; /* 150% */
          color: var(--dark-brown, #6a451a);
        }
      }
    }
    input {
      background: #f2f2f2;
      padding-left: 10px;
      border: none;
    }
    input:focus {
      outline: none;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.successMessage {
  background: var(--brand-ego-500, #1fad64);
  border-radius: 4px;
  width: 400px;
  height: 40px;
  padding: 16px;
  align-items: center;
  margin-bottom: 24px;
  display: flex;

  justify-content: space-between;
  p {
    color: var(--white-100, #fff);
    width: 90%;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px; /* 150% */
    letter-spacing: -0.12px;
  }
  div {
    color: white;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
  }
}

.errorMessage {
  background: var(--brand-ego-500, #db4343);
  border-radius: 4px;
  width: 400px;
  height: 50px;
  padding: 16px;
  align-items: center;
  margin-bottom: 24px;
  display: flex;

  justify-content: space-between;
  p {
    color: var(--white-100, #fff);
    width: 90%;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px; /* 150% */
    letter-spacing: -0.12px;
  }
  div {
    color: white;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
  }
}

.amountBtn {
  height: 48px;
  flex-shrink: 0;
  width: 100%;
  background: var(--mobile-primary-color-default-color, #fd9c42);
  border-radius: 6px;
  margin-top: 29px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  color: #fff;
}
