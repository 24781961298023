.root {
  width: 397px;
  height: 481px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 29px 36px;
  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    color: var(--gray-1, #333);
  }
  span {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
  button {
    width: 100%;
    background: var(--mobile-primary-color-default-color, #fd9c42);
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 900;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    height: 50px;
    margin-top: 42px;
    transition: 0.4s;
  }
  button:hover {
    opacity: 0.7;
  }
}

.options {
  width: 100%;
  margin-top: 32px;
}
