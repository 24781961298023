.rootPlan {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

.topName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      border-radius: 50%;
    }
    h1 {
      color: var(--gray-1, #333);
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 2px;
    }
    span {
      color: var(--gray-3, #828282);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.plan {
  margin-top: 30px;
}

.planHeader {
  margin-bottom: 27px;
  h1 {
    font-size: 20px;
    font-weight: 900;
    color: var(--mobile-primary-color-brown, #b17f34);
  }
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}

.planCards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.planGoalCard {
  background: #fff;
  border-radius: 4px;
  width: 503px;
  height: 289px;
  margin-bottom: 31px;
  .goalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 41px;
    border-bottom: 1px solid #e6e6e6;
    justify-content: space-between;
    h1 {
      font-size: 18px;
      font-weight: 900;
      line-height: 27px;
      color: var(--gray-2, #4f4f4f);
    }
    button {
      background: var(--mobile-primary-color-default-color, #ffebd9);
      width: 62px;
      height: 40px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      border-radius: 5px;
      border: none;
      transition: 0.4s;
      text-align: center;
      font-size: 16px;
      font-weight: 900;
      color: var(--dark-brown, #6a451a);
      cursor: pointer;
    }
    button:hover {
      opacity: 0.6;
    }
  }
}

.goalContent {
  display: flex;
  height: 75%;
  padding: 20px 10px;
  margin-top: 5px;
  width: 100%;
  overflow: auto;
  button {
    height: 48px;
    border: none;
    width: 182px;
    background: #fee1c7;
    text-align: center;
    margin-top: 25px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}

.continueBtn {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  background: var(--mobile-primary-color-default-color, #fd9c42);
  height: 55px;
  width: 181px;
  align-self: flex-end;
  border: none;
  cursor: pointer;
  margin-bottom: 150px;
  border-radius: 6px;
  margin-top: 33px;
}

.continueBtn:hover {
  opacity: 0.6;
}

.medListWrapper {
  width: 100%;
  overflow: auto;
  max-height: 200px;
  .medList {
    background-color: #f0f0f0;
    width: 100%;
    padding: 15px;
    margin-top: 5px;
  }
}
.therapyContent {
  .therapyHeader {
    h1 {
      font-size: 17px;
      color: var(--gray-2, #4f4f4f);
    }
  }
  span,
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}

.saveAndContinueBtnContainer {
  align-items: center;
  align-self: flex-end;
  button {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    color: #fff;
    background: var(--mobile-primary-color-default-color, #fd9c42);
    height: 55px;
    width: 181px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
  }
  button:hover {
    opacity: 0.6;
  }
}
