.root {
  width: 100%;
  display: flex;
  justify-content: space-between;



  .right {
    .patientContainer {
      min-height: 515px;
    }


    h4 {
      margin-top: 10px;
      color: #906945;
      text-align: left;
    }

    .headerText {
      font-size: 16px;
      color: #906945;
      margin-top: 20px;

    }

    .rightHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 15px 0px;
      border-bottom: 1px solid #e6e6e6;
      align-items: center;

      h3 {
        width: 141px;
        font-size: 12px;
        font-weight: 800;
        color: #906945
      }
    }



    .history {
      table {
        border-collapse: collapse;
      }

      td {
        padding: 20px;
      }

      tr {
        border-bottom: 1px solid #E6E6E6;
        color: #333333;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
      }

      button {
        color: var(--dark-brown, #6A451A) !important;
        font-size: 14px;
        font-weight: 900;
        background: none;
        border: none;
        cursor: pointer;
      }
    }

  }
}

.addressInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.street {
  padding: 0px 20px;
  margin-bottom: 10px;
  font-weight: normal;

}

.information {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .info:nth-child(odd) {
    width: 45%;
  }

  .info:nth-child(even) {
    width: 45%;
  }

  .info {
    margin-bottom: 32px;

    span {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: #906945
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: var(--gray-1, #333);
    }
  }
}

.addressContainer {
  width: 60%;
}


.creator {
  padding: 5px 0px;
  display: flex;
  flex-direction: column;

  .nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f7ff;
    padding: 20px 26px;
    width: 100%;
    flex-shrink: 0;

    border-radius: 4px;
    margin-top: 6px;
  }

  .name {
    height: 76px;

    p {
      font-size: 16px;
      font-weight: 900;
      color: var(--gray-1, #333);
    }

    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 21px;
      text-transform: uppercase;
      color: #906945
    }

  }
}

.viewCarePlanBtn {
  align-self: flex-end;

  button {
    background-color: #fd9c42;
    height: 40px;
    width: 80px;
    font-size: 15px;
    font-weight: 500;
    padding: 2px;
    cursor: pointer;
  }
}




.generalInfo {
  min-height: 515px;

  .subHeader {
    font-size: 18px;
    font-weight: 900;
    color: #b17f34;
    margin: 0;
  }
}