.customCheckbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 25px; /* Adjust this value to change the space between the checkbox and the label text */
}

.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: -10px;
  left: 0;
  // bottom: 0;
  width: 20px; /* Adjust this value to change the size of the checkbox */
  height: 20px; /* Adjust this value to change the size of the checkbox */
  border-radius: 4px;
  background-color: #ffffff; /* Arrow color (white) */
  border: 2px solid #fd9c42; /* Checker color */
}

.customCheckbox input:checked ~ .checkmark {
  background-color: #fd9c42; /* Checker color when checkbox is checked */
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.customCheckbox input:checked ~ .checkmark:after {
  display: block;
}

.customCheckbox .checkmark:after {
  left: 5px; /* Adjust this value to change the position of the arrow */
  top: 1px; /* Adjust this value to change the position of the arrow */
  width: 6px; /* Adjust this value to change the size of the arrow */
  height: 10px; /* Adjust this value to change the size of the arrow */
  border: solid white; /* Arrow color */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
