.location_wrapper {
  display: flex;
  flex-direction: column;
  color: #828282;
  justify-content: flex-start;
  align-items: center;
  padding: 21px;
  width: 100%;
  height: 100%;
  gap: 1rem;
}

.location_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 504px;
  height: 92px;
  flex-shrink: 0;
  gap: 0.5rem;

  padding: 21px;
  border-radius: 8px;
  border: none;

  background: #fff5ec;
}
.location_content_p {
  color: var(--Gray-2, #4f4f4f);
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.tableContainer {
  background-color: #fff;
  padding: 63px 42px;
  // margin-top: 10px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  border-radius: 8px;
  width: 100%;
  font-family: 'Avenir';
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

table {
  width: 100%;
  font-family: 'Avenir';
  font-size: 20px;
}

.tableHeader {
  font-size: 1.2rem;
  font-weight: 800;
  font-family: 'Avenir-black';
  color: var(--dark-brown, #6a451a);
}

.tableRow {
  td {
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: 'Avenir-black';
    color: var(--gray-2, #4f4f4f);
  }
  .progress,
  .success,
  .failed {
    font-size: 13px;
    font-weight: 900;
    text-transform: uppercase;
  }
  .progress {
    color: #b17f34;
  }
  .success {
    color: var(--green-2, #27ae60);
  }
  .failed {
    color: var(--red, #eb5757);
  }
}
