.root {
  flex-shrink: 0;
  width: 720px;
  padding: 5px 30px;
  .header {
    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--gray-1, #333);
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-2, #4f4f4f);
    }
  }
}

.calendar {
  margin-right: 20px;
}

.appointment {
  width: 100%;
  justify-content: space-between;
  display: flex;
  height: max-content;
  flex-direction: row;
}

.time {
  font-size: 16px;
  font-weight: 900;
  color: var(--gray-1, #333);
  margin-bottom: 5px;
}

.details {
  margin-top: 5px;
  margin-bottom: 5px;
  background: var(--mobile-whites-gray-white, #f2f2f2);
  border-radius: 5px;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  h1 {
    font-size: 18px;
    font-weight: 900;
    color: var(--gray-1, #333);
  }
  a {
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: underline;
    color: var(--gray-2, #4f4f4f);
  }
  p {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}
.meetingLink {
  width: 100%;
  input {
    width: 100% !important;
    padding: 10px;
  }
}
