.terms-conditions-container {
  display: flex;
  gap: 5rem;
  small {
    cursor: pointer;
    color: #666565;
    color: var(--Dark-Brown, #6a451a);
    font-family: Avenir-black;
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px;
    text-decoration-line: underline;
  }
}

.privacy-policy-link {
  color: inherit;
  text-decoration: none;
}
