.securityContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 21px 50px;
  gap: 1rem;
}

.securityHeader {
  width: 332px;
  height: 24px;

  width: 100%;
  height: 50px;

  flex-shrink: 0;
  color: var(--Gray-1, #333);
  font-family: 'Avenir';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 45.5px;
}

.securityDescription {
  display: flex;
  width: 612px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Gray-2, #4f4f4f);
  font-family: 'Avenir';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.securityActivate {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 1rem;
}
.svg {
  width: 40px;
  height: 22px;
  flex-shrink: 0;
}
.activateDescripton {
  display: flex;
  width: 612px;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Gray-1, #333);
  font-family: 'Avenir';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
}
.changePassword {
  display: flex;
  width: 332px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Gray-1, #333);
  font-family: 'Avenir';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 45.5px; /* 227.5% */
}
.securityInput {
  display: flex;
  gap: 5rem;
  margin-top: 50px;
  input {
    width: 370px;
    height: 61px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #fff;
    padding-left: 10px;
  }
}
.securityButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  button {
    width: 325px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Mobile-Primary-Color-Default-Color, #fd9c42);
    border: none;
    margin-right: 80px;
    cursor: pointer;
  }
}
