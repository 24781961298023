.verification-pending-container {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 20px;
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #4f4f4f;
  }
}
