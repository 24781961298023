.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;


}

.contents {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.left_content {
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right_content {
  width: 50%;
}

.locate_field {
  width: 65%;

  margin-top: 15px;
}

.item_field {
  width: 75%;
  margin-left: 50px;
  margin-top: 5px;
}

.foot_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-right: 110px;

}

.btn_div {
  display: flex;

  justify-content: center;
  width: 100%;
}

.schedule_fee {
  width: 30%;
  margin-left: 35px;

  padding: 15px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}


:global {

  .ant-picker-content th {
    font-size: 16px;
  }

  .ant-picker-content td {
    font-size: 16px;
  }

}

.serviceInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;


  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #6a6a6a;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 80%;
  margin-left: 50px;
}

.container {
  background: #fff;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  border-radius: 8px;
  width: 800px;
  padding: 39px 79px;

  .header {
    width: 50%;
    margin-left: 60px;

    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--gray-1, #333);
    }

    div {
      background: var(--gray-1, #333);
      border-radius: 4px;
      height: 30px;
      flex-shrink: 0;
      width: 159px;

      h2 {
        text-align: center;
        margin-top: 30px;
        font-size: 14px;
        padding-top: 4px;
        font-weight: 900;
        margin-bottom: 19px;
        line-height: 21px;
        /* 150% */
        color: #fff;
      }
    }
  }
}

.psych_content {
  width: 75%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.psych_header {
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
}

.psych_fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4em;
  justify-content: flex-start;
}

.deleteButton {
  color: #ed2d2d;
  width: 75%;
  margin-left: 50px;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.successContainer {
  background: var(--brand-ego-500, #1fad64);
  border-radius: 4px;
  width: 612px;
  height: 40px;
  padding: 16px;
  align-items: center;
  margin-bottom: 24px;
  display: flex;

  p {
    color: var(--white-100, #fff);
    width: 90%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    /* 150% */
    letter-spacing: -0.12px;
  }

  div {
    color: white;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
  }

  background: var(--brand-ego-500, #1fad64);
  border-radius: 4px;
  width: 800px;
  height: 40px;
  padding: 16px;
  align-items: center;
  margin-bottom: 24px;
  display: flex;

  p {
    color: var(--white-100, #fff);
    width: 90%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    /* 150% */
    letter-spacing: -0.12px;
  }

  div {
    color: white;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
  }
}

.add {
  display: flex;
  margin-top: 19px;
  flex-direction: row;
  align-items: center;

  margin-left: 57px;

  cursor: pointer;

  img {
    margin-right: 4px;
  }

  span {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}

.errorContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
  background: var(--brand-cherry-600, #db4343);
  border-radius: 4px;
  width: 800px;
  height: 80px;
  padding: 16px;
  flex-shrink: 0;
  margin-bottom: 24px;
  margin-left: 120px;
  justify-content: space-between;

  img {
    margin-right: 12px;
  }

  h1 {
    font-size: 15px;
    font-weight: 900;

    line-height: 21px;
    /* 140% */
    letter-spacing: -0.12px;
    color: var(--white-100, #fff);
  }

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 21px;
    /* 161.538% */
    color: #fff;
    width: 75%;
  }

  .errorCancel {
    color: white;
    font-size: 20px;
    font-weight: 900;
    padding: 10px;
    cursor: pointer;
  }
}

.dropdown {
  width: 150px;
}

.largeDropdown {
  width: 100%;

}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 51px;
  margin-right: 110px;
  margin-left: 50px;
  width: 100%;

  button:first-child {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    color: #fff;
    background: var(--mobile-primary-color-default-color, #fd9c42);
    height: 55px;
    width: 500px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
  }

  button:hover {
    opacity: 0.5;
  }
}

.btn_done {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  background: var(--mobile-primary-color-default-color, #fd9c42);
  height: 55px;
  width: 100px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}

.modalRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 373px;
  width: 364px;
  justify-content: center;

  h1 {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    margin-top: 25px;
    margin-bottom: 16px;
    color: var(--gray-1, #333);
  }

  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-2, #4f4f4f);
    line-height: 24px;
    /* 150% */
    margin-bottom: 47px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 373px;
  width: 364px;
  justify-content: center;

  h1 {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    margin-top: 25px;
    margin-bottom: 16px;
    color: var(--gray-1, #333);
  }

  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-2, #4f4f4f);
    line-height: 24px;
    /* 150% */
    margin-bottom: 47px;
  }
}