.question_container {
   display: flex;
   flex-direction: column;
   justify-content: center;

   background-color: #ffffff;
   height: 116px;
   padding: 20px;
   width: 100%;
   color: #6A451A;

   p {
      color: #BEBEBE;
   }
}