.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  min-height: 85vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    .downloadBtn {
      width: 100;
      button {
        background: none;
        color: var(--Dark-Brown, #6a451a);
      }
    }
  }
}
.subHeading {
  padding: 5px;
  h1 {
    color: var(--Gray-1, #333);
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 10px;
  }
  .headingImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 16px;
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }
    h2 {
      font-size: 18px;
      font-weight: 900;
      line-height: 27px; /* 150% */
      color: var(--Dark-Brown, #6a451a);
    }
    span {
      font-size: 15px;
      font-weight: 500;
      line-height: 24px; /* 160% */
      color: var(--Gray-2, #4f4f4f);
    }
  }
}

.content {
  width: 100%;
  padding: 0 38px;
  background: #fff;
  box-shadow: 0px 6px 40px 0px rgba(196, 203, 214, 0.16);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .left {
    border-right: 1px solid #f2f2f2;
    width: 35%;
    .leftHeading {
      padding-top: 47px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      img {
        border-radius: 50%;
        margin-right: 9px;
      }
      .careNumber {
        margin-top: 5px;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 12px;
          p {
            text-align: center;
            font-size: 12px;
            font-weight: 900;
            background: var(--Gray-1, #333);
            padding: 5px;
            margin-right: 9px;
            border-radius: 4px;
            line-height: 16px; /* 133.333% */
            color: #fff;
          }
        }
        h1 {
          font-size: 18px;
          font-weight: 800;
          color: var(--Gray-1, #333);
        }
        span {
          font-size: 15px;
          font-weight: 500;
          line-height: 24px; /* 160% */
          color: var(--Gray-2, #4f4f4f);
        }
      }
    }
  }
}

.stressors {
  margin-top: 37px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  .stressContents {
    margin-top: 4px;
  }
  h1 {
    color: var(--Dark-Brown, #6a451a);
    font-size: 17px;
    font-weight: 900;
    line-height: 27px; /* 158.824% */
  }
  .stress {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    margin-bottom: 8px;
    span {
      color: #fff;
      background: #b17f34;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      padding: 1.5px 7px 0.1px 7px;
      font-weight: 900;
      line-height: 21px; /* 175% */
      color: #fff;
      border-radius: 50%;
    }
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;
      margin-left: 12px;
      color: var(--gray-2, #4f4f4f);
    }
  }
}

.assessment {
  padding: 37px 0;
  border-bottom: 1px solid #f2f2f2;
  padding-right: 14px;

  .assess {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-between;
  }
  h1 {
    color: var(--Dark-Brown, #6a451a);
    font-size: 17px;
    font-weight: 900;
    line-height: 27px; /* 158.824% */
    margin-bottom: 15px;
  }
  .singleAssessment {
    p {
      font-size: 14px;
      font-weight: 900;
      color: var(--Gray-3, #828282);
    }
    span {
      font-size: 16px;
      font-weight: 500;
      color: var(--Gray-1, #333);
    }
  }
}

.shortTermGoal {
  padding: 32px 0;
  padding-right: 32px;
  border-bottom: 1px solid #f2f2f2;

  h1 {
    font-size: 17px;
    font-weight: 900;
    line-height: 27px; /* 158.824% */
    color: var(--Dark-Brown, #6a451a);
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; /* 160% */
    color: var(--Gray-1, #333);
  }
}

.longTermGoals {
  padding-top: 37px;
  padding-right: 32px;
  border-bottom: 1px solid #f2f2f2;

  h1 {
    font-size: 17px;
    font-weight: 900;
    line-height: 27px; /* 158.824% */
    color: var(--Dark-Brown, #6a451a);
  }
  p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 24px; /* 160% */
    color: var(--Gray-1, #333);
  }
}

.diagnosis {
  padding-right: 32px;
  h1 {
    font-size: 17px;
    margin-top: 39px;
    font-weight: 900;
    line-height: 27px; /* 158.824% */
    margin-bottom: 16px;
    color: var(--Dark-Brown, #6a451a);
  }
  p {
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid var(--Gray-6, #f2f2f2);
    background: #f6f6f6;
    line-height: 24px; /* 160% */
    color: var(--Gray-1, #333);
    margin-bottom: 10px;
    padding: 8px 16px;
  }
  margin-bottom: 40px;
}

.right {
  padding: 37px;
  width: 67%;
  h1 {
    font-size: 17px;
    font-weight: 900;
    line-height: 27px; /* 158.824% */
    color: var(--Dark-Brown, #6a451a);
    margin-bottom: 16px;
  }
}

.medication {
  width: 100%;
}
.singleMedication {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid var(--Gray-6, #f2f2f2);
  background: #f6f6f6;
  padding: 16px;
  border-radius: 4px;
  .medicationTitle {
    p {
      color: #fff;
      background: #b17f34;
      text-align: center;
      font-size: 9px;
      font-style: normal;
      padding: 1px 4px 0.1px 4px;
      font-weight: 900;
      line-height: 15px; /* 175% */
      color: #fff;
      border-radius: 50%;
      margin-right: 16px;
    }
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  h1 {
    font-size: 16px;
    font-weight: 500;
    color: var(--Gray-1, #333);
    margin-bottom: 4px;
    line-height: 24px; /* 150% */
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: var(--Gray-3, #828282);
    line-height: 24px; /* 171.429% */
  }
  h2 {
    font-family: Avenir;
    font-size: 13px;
    margin-bottom: 4px;
    color: var(--Gray-3, #828282);
    font-weight: 900;
  }
  h5 {
    font-size: 15px;
    font-weight: 500;
    color: var(--Gray-2, #4f4f4f);
  }
}

.therapy {
  margin-top: 48px;

  .therapyContent {
    border: 1px solid var(--Gray-6, #f2f2f2);
    background: #f6f6f6;
    border-radius: 4px;
    padding: 26px 16px 10px 47px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  span {
    font-size: 13px;
    font-weight: 900;
    color: var(--Gray-3, #828282);
  }
  p {
    font-size: 15px;
    font-weight: 500;
    color: var(--Gray-1, #333);
  }
}

.schedules {
  margin-top: 48px;
  h1 {
    font-size: 17px;
    font-weight: 900;
    line-height: 27px;
    color: var(--Dark-Brown, #6a451a);
  }
  .schedule {
    border: 1px solid var(--Gray-6, #f2f2f2);
    background: #f6f6f6;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    width: 519px;
    padding: 12px 32px;
    align-items: center;
    gap: 87px;
    display: flex;
    span {
      font-size: 13px;
      font-weight: 900;
      color: var(--Gray-3, #828282);
    }
    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 150%;
      color: var(--Gray-1, #333);
    }
  }
}

.appointment {
  margin-top: 48px;
  h1 {
    font-size: 17px;
    font-weight: 900;
    line-height: 27px;
    color: var(--Dark-Brown, #6a451a);
  }
  div {
    display: flex;
    width: 519px;
    padding: 12px 32px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--Gray-6, #f2f2f2);
    background: #f6f6f6;
    border-radius: 4px;
    h2 {
      font-size: 16px;
      font-weight: 900;
      color: var(--Gray-1, #333);
    }
    a {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      text-decoration-line: underline;
      color: var(--Gray-2, #4f4f4f);
    }
  }
}

.requests {
  margin-top: 48px;
  h1 {
    font-size: 17px;
    margin-bottom: 16px;
    font-weight: 900;
    line-height: 27px; /* 158.824% */
    color: var(--Dark-Brown, #6a451a);
  }
  div {
    width: 519px;
    padding: 12px 32px;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 87px;
    display: flex;
    border: 1px solid var(--Gray-6, #f2f2f2);
    background: #f6f6f6;
    border-radius: 4px;
    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 150%; /* 22.5px */
      color: var(--Gray-1, #333);
    }
  }
}
.topBtnContainer {
  display: flex;
}

.sendToPatientBtn {
  margin-left: 10px;
}
