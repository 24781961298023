.dashboard-users-table {
  margin-top: 58px;
  width: 100%;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: 'Avenir';

    .du-patient-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.5rem;

      p {
        font-size: 18px;
        font-weight: 800;
        font-family: 'Avenir';
        line-height: 21px;
        color: #6a451a;
        margin-right: 10px;
      }

      span {
        color: var(--white, #fff);
        text-align: center;
        font-size: 0.9rem;
        display: inline-flex;
        font-weight: 800;
        font-family: 'Avenir';
        line-height: 142%;
        letter-spacing: 0.07px;
        background: var(--red, #eb5757);
        border-radius: 24px;
        padding: 2px 10px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .du-view-all {
    span {
      color: var(--gray-2, #4f4f4f);
      text-align: right;
      font-size: 16px;
      font-family: 'Avenir';
      font-style: normal;
      cursor: pointer;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.appContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: crimson;
  color: #ffffff;
  height: 38px;
  padding: 10px 20px;
  border-radius: 50px;
  fill: #fff;
  filter: drop-shadow(0px 6px 58px rgba(196, 203, 214, 0.1));
  max-width: 395px;

  input {
    width: 370px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--gray-3, #828282);
  }

  img {
    width: 18px;
    margin-right: 12px;
  }

  input:focus {
    outline: none;
  }
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

}