.status-indicator {
  display: flex;
  align-items: center;

  span {
    font-weight: lighter;
    font-size: 12px;
    color: #4f4f4f;
    font-family: "Avenir";
  }
}

.status-icon {
  width: 13px;
  height: 13px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.status-icon img {
  width: 100%;
  height: 100%;
}

.status-icon.green {
  background-color: green;
}

.status-icon.grey {
  background-color: grey;
}