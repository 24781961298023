.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
}

.topName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      border-radius: 50%;
    }
    h1 {
      color: var(--gray-1, #333);
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 2px;
    }
    span {
      color: var(--gray-3, #828282);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.assessPatientRoot {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  h1:first-child {
    font-size: 20px;
    font-weight: 800;
    color: var(--mobile-primary-color-brown, #b17f34);
  }
  span:first-child {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
  .contents {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .left {
      width: 34%;
      padding: 10px 26px;
      border-radius: 8px;
      min-width: 349px;
      background: #fff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
      .assess {
        p {
          color: var(--gray-3, #828282);
          font-size: 14px;
          font-weight: 900;
          line-height: 22.5px;
        }
        div {
          background: var(--mobile-primary-color-cream-yellow, #f8eedf);
          margin-top: 4px;
          border-radius: 5px;
          height: 60px;
          flex-shrink: 0;
          padding: 10px 24px;

          h1 {
            font-size: 14px;
            font-weight: 500;
            color: var(--gray-1, #333);
          }
        }
      }
      .stressors {
        margin-top: 37px;
        .stressContents {
          padding: 20px;
          margin-top: 4px;
          background: #f8eedf;
        }
        h1 {
          font-size: 14px;
          font-weight: 900;
          line-height: 22.5px;
          color: var(--gray-3, #828282);
        }
        .stress {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px;
          margin-bottom: 8px;
          border-bottom: 1px solid #e0e0e0;
          span {
            color: #fff;
            background: #b17f34;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            padding: 1.5px 7px 0.1px 7px;
            font-weight: 900;
            line-height: 21px; /* 175% */
            color: #fff;
            border-radius: 50%;
          }
          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.5px;
            margin-left: 12px;
            color: var(--gray-2, #4f4f4f);
          }
        }
      }
      .goals {
        margin-top: 37px;
        h1 {
          font-size: 14px;
          font-weight: 900;
          line-height: 22.5px; /* 160.714% */
          color: var(--gray-3, #828282);
        }
        .goalsContent {
          background-color: #f8eedf;
          margin-top: 4px;
          //   padding: 23px;
          .goal {
            // display: flex;
            padding: 8px;
            flex-direction: row;
            // align-items: flex-start;
            span {
              color: #fff;
              background: #b17f34;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              padding: 1.5px 7px 0.1px 7px;
              font-weight: 900;
              line-height: 21px; /* 175% */
              color: #fff;
              border-radius: 50%;
            }
            p {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.5px;
              margin-left: 12px;
              color: var(--gray-2, #4f4f4f);
            }
          }
        }
      }
      .longGoals {
        margin-top: 32px;
        h1 {
          font-size: 14px;
          font-weight: 900;
          line-height: 22.5px;
          color: var(--gray-3, #828282);
        }
        .longGoal {
          background: #f8eedf;
          display: flex;
          min-height: 203px;
          max-height: 303px;
          overflow: auto;
          padding: 24px;
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--gray-2, #4f4f4f);
          }
        }
      }
    }
    .right {
      width: 63%;
      border-radius: 8px;
      min-width: 349px;

      background: #fff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
      .head {
        border-bottom: 1px solid #e6e6e6;
        h1 {
          font-size: 18px;
          padding: 16px 76px;
          font-weight: 900;
          line-height: 27px;
          color: var(--gray-2, #4f4f4f);
        }
      }
      .rightAssess {
        background: #f8eedf;
        margin: 26px 81px;
        padding: 26px 23px;
        border-radius: 6px;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: var(--gray-1, #333);
        }
      }
      .rightDropdowns {
        margin: 42px 81px;
        h1 {
          font-size: 16px;
          font-weight: 900;
          line-height: 27px;
          color: var(--dark-brown, #6a451a);
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 51px 81px;
        button {
          width: 100%;
          height: 62px;
          margin-bottom: 24px;
          border-radius: 6px;
          font-size: 18px;
          font-weight: 900;
          cursor: pointer;
          transition: 0.4s;
          text-align: center;
        }
      }
    }
  }
}
