.container {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  width: 650px;
  align-items: center;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.progressBar::before {
  content: '';
  position: absolute;
  background-color: #bdbdbd;
  top: 25%;
  left: 25px;
  transform: translateY(-50%);
  border-radius: 5px;
  width: 90%;
  right: -45px;
  transition: 0.4 ease;
  height: 2px;
}

.progress {
  position: absolute;
  background-color: #fd9c42;
  top: 25%;
  left: 25px;
  right: -45px;
  transform: translateY(-50%);
  border-radius: 5px;
  width: 10%;
  transition: 0.4 ease;
  height: 2px;
}

.circle {
  display: flex;
  z-index: 2;
  font-weight: 900;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.4 ease;
  font-size: 15px;
  div {
    display: flex;
    align-items: center;
    transition: 0.4 ease;
    justify-content: center;
    border: 2px solid #c1c1c1;
    background-color: #fafafa;
    color: #c1c1c1;
    border-radius: 50%;
    height: 34px;
    width: 34px;
  }
  p {
    margin-top: 15px;
    color: #adacac;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
  }
}

.circleActive {
  display: flex;
  z-index: 2;
  font-weight: 900;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.4 ease;
  font-size: 15px;
  div {
    display: flex;
    align-items: center;
    transition: 0.4 ease;
    justify-content: center;
    border: 2px solid #fd9c42;
    background-color: #fd9c42;
    color: #fff;
    border-radius: 50%;
    height: 34px;
    width: 34px;
  }
  p {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
    font-weight: 900;
    line-height: 21px;
    color: var(--mobile-primary-color-brown, #b17f34);
  }
}
