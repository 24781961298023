.optionsContainer {
  display: flex;
  flex-direction: row;
}

.optiondiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 116px;
  padding: 10px;
  background-color: #ffffff;
  border: rgba(1, 0.5, 0.5, 0.5);
}



.highlight {
  background-color: #f4f1ed;
  border: 2px solid #fd9c42;
}