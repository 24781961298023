.button-container {
  width: 100%;
  button {
    width: 100%;
    background: #fd9c42;
    border-radius: 6px;
    outline: none;
    border: none;
    padding: 10px 40px;
    text-align: center;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    cursor: pointer;
    transition: opacity 0.3s, cursor 0.3s;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.button-container-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
