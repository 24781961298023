/* Update CSS class names to match functional component */

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: inherit;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
}

.RichEditor-nav {
  display: flex;
  flex-direction: row;
}

.RichEditor-editor {
  border-bottom: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  padding: 5px;
}

.RichEditor-editor .public-DraftEditor-content {
  max-height: 842px;
  min-height: 300px;
  overflow: auto;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
