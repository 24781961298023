.root {
  width: 924px;
  height: 432px;
  padding: 39px 48px;
  .header {
    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--gray-1, #333);
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-1, #333);
    }
  }
  .contents {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 44px;
    width: 100%;
  }
  .left {
    h1 {
      font-size: 16px;
      font-weight: 900;
      color: var(--dark-brown, #6a451a);
      margin-bottom: 10px;
    }
    .safety {
      margin-top: 42px;
    }
  }
}

.right {
  h1 {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
    margin-bottom: 4px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 396px;
  label {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: var(--gray-2, #4f4f4f);
  }
  textarea {
    background: var(--mobile-whites-gray-white, #f2f2f2);
    border-radius: 5px;
    border: none;
    padding: 16px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    line-height: 21px;
    color: var(--gray-2, #4f4f4f);
  }
  textarea:focus {
    outline: none;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
  button {
    width: 100%;
    background: var(--mobile-primary-color-default-color, #fd9c42);
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 900;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    height: 50px;
    margin-top: 35px;
    transition: 0.4s;
  }
  button:hover {
    opacity: 0.7;
  }
}

.exceeded {
  color: red !important;
}

.done {
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: space-between;
  button {
    color: #fff;
    background-color: #fd9c42;
    width: 196px;
    height: 55px;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    margin-top: 13px;
  }
}
