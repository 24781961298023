.account-verification-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;


  .account-verification-form-header {
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 45px;

      text-align: center;

      color: #333333;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 141% */

      text-align: center;

      color: #666565;
    }
  }

  width: 100%;

  .account-verification-form-input-container {
    margin-top: 10px;
    width: 350px;
  }
}

.input-column {
  input {
    width: 100%;
  }

  display: flex;
  justify-content: space-between;

  .mr {
    input {
      width: 100%;
    }
  }
}

.input-column {
  margin-top: 10px;
}

.account-verification-form-password-check {
  .mb {
    margin-bottom: 5px;
  }

  margin-top: 5px;
  padding: 2px;

  div:first-child {
    margin-right: 5px;
  }

  .password-check-container {
    display: flex;
  }
}

.account-verification-form-button-container {
  margin-top: 20px;
}