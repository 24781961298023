.modalOverlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;

}

.modalContent {
  overflow: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-height: 90%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow: auto;
}

.modalOverlay.open {
  opacity: 1;
}

.modalContent.open {
  opacity: 1;
  transform: translateY(0);
}

.closeButton {
  position: absolute;
  top: 0;
  right: -35px;
  align-self: flex-start;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
