.dashboard-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dashboard-overview-card-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        .overview-card-left {
            width: 49%;
        }

        .overview-card-right {
            width: 49%;
        }
    }
}