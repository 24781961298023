.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  font-family: 'Avenir';
}

.patientDetails {
  width: 100%;

  .patientHeader {
    font-size: 20px;
    margin-top: 0;
    font-family: 'Avenir';
    font-weight: 900;
    margin-bottom: 33px;
    line-height: 28px;
    color: var(--gray-1, #333);
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-family: 'Avenir';

    button {
      text-align: center;
      font-size: 16px;
      color: #fff;
      font-weight: 900;
      background: var(--mobile-primary-color-default-color, #fd9c42);
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      cursor: pointer;
      border-radius: 6px;
      width: 182px;
      height: 48px;
      flex-shrink: 0;
      border: none;
    }

    button:disabled {
      opacity: 0.5;
      box-shadow: none;
      cursor: not-allowed;
    }

    button:hover+.tooltip {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .patientProfile {
    display: flex;
    flex-direction: row;
    font-family: 'Avenir';

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    .user {
      margin-left: 15px;

      h1 {
        font-size: 16px;
        font-weight: 900;
        line-height: 27px;
        color: var(--dark-brown, #6a451a);
      }

      span {
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
        color: var(--gray-2, #4f4f4f);
      }
    }
  }
}

:global {
  .ant-spin-dot-item {
    background-color: #fd9c42 !important;
  }
}

.questionsContainer {
  display: flex;
  margin-top: 30px;

  gap: 1rem;
  max-width: 1048x;

  .left_question_div {
    width: 50%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .right_question_div {
    width: 50%;
    // border: 1px solid green;
    display: flex;
    flex-direction: row;


  }
}

.answerBtn {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

}