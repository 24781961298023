.root {
  height: auto;
  flex-shrink: 0;
  width: 800px;
  padding: 31px 64px;
  overflow: hidden;

  gap:1rem;

  .header {
    h1 {
      font-size: 22px;
      font-weight: 900;
      color: var(--dark-brown, #6a451a);
      margin-bottom: 13px;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--gray-2, #4f4f4f);
    }
    p {
      text-align: center;
      font-size: 14px;
      font-weight: 900;
      width: max-content;
      text-transform: uppercase;
      color: var(--mobile-primary-color-brown, #b17f34);
      background: #fff0e3;
      padding: 8px 12px;
      border-radius: 4px;
      margin-top: 32px;
    }
  }
}

.contentsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 21px;
  align-items: center;
  flex-wrap: wrap;
  height: 300px;
  gap: 0.5rem;

}

.transact {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  width: 210px;
  margin-bottom: 25px;
  flex-direction: column;
  align-items: flex-start;
  span {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-2, #4f4f4f);
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: var(--gray-1, #333);
  }
}

.content:nth-child(5) {
  width: 300px !important;
}

.downloadButton {
 

  border-radius: 5px;
  border: none;
  margin-top: 100px;
  width: 50%;
  height: 30%;
  text-align: center;
  font-size: 13px;
  font-weight: 900;
  color: #ffffff;
  background-color: #fd9c42;
  cursor: pointer;

}
.btn {
  display: flex;
  gap: 2rem;

  margin-top: 70px;
 

}
.resubmit{
  display: flex;
 width: 300px;
 height: 50px;
 justify-content: center;
 align-items: center;
 background-color: #fd9c42;
 border-radius: 7px;
 color: #ffffff;
border: none;


}