.sign-up-form-container {


  .sign-up-form-header {
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 45px;
      /* or 150% */

      text-align: center;

      /* Gray 1 */

      color: #333333;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;

      /* identical to box height, or 141% */

      text-align: center;

      color: #666565;
    }
  }

  width: 100%;

  .sign-up-form-input-container {
    margin-top: 10px;
    width: 100%;
  }
}

.input-row {
  .input-container {
    width: 48%;
  }

  display: flex;
  justify-content: space-between;

  .mr {
    input {
      width: 100%;
    }
  }
}

.input-column,
.input-row {
  margin-bottom: 10px;
}

.sign-up-form-password-check {
  .mb {
    margin-bottom: 5px;
  }

  margin-top: 5px;
  padding: 2px;

  div:first-child {
    margin-right: 5px;
  }

  .password-check-container {
    display: flex;
    justify-content: space-between;
  }
}

.sign-up-form-button-container {
  margin-top: 20px;
}

.have-account {
  padding: 15px;
  display: flex;
  justify-content: center;

  small {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: #4f4f4f;
  }

  .nav-link {
    margin-left: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #215543;
    text-decoration: none;
    cursor: pointer;
  }
}

.phone-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  width: 100%;


  input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    width: 100%;

  }
}


.react-tel-input {
  width: 100% !important;

  .open {
    z-index: 4 !important;

  }

  .selected-flag {
    border: 1px solid #F2F2F2 !important;
    background-color: #fff !important;
  }

  input {
    padding: 23px;
    width: 100% !important;
  }
}