.root {
  background: #ffffffff !important;
  height: 125px;
  margin-bottom: 27px;
  .innerRoot {
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    img {
      cursor: pointer;
    }
  }
}

.container {
  background-color: #f2f2f2;
}
