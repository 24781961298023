.login-form-container {

  .login-form-inner-container {
    width: 100%;


  }


  .login-form-header {
    min-width: 370px;

    .imag-container {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 25%;
      }

    }

    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      font-family: 'Avenir';
      line-height: 45px;
      text-align: center;
      color: #333333;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-family: 'Avenir';
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #666565;
    }
  }



  .login-form-input-container {
    margin-top: 10px;
    width: 100%;
  }

  .input-column {
    margin-top: 10px;
  }

  .login-form-button-container {
    margin-top: 30px;
  }

  .have-account {
    padding: 15px;
    display: flex;
    justify-content: center;

    small {
      font-style: normal;
      font-size: 18px;
      line-height: 21px;
      color: #4f4f4f;
    }

    .nav-link {
      margin-left: 10px;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      color: #215543;
      text-decoration: none;
      cursor: pointer;
    }
  }
}