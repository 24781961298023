.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  justify-content: space-between;
  width: 100%;
}

.left {
  width: 100%;
  border-radius: 8px;
  overflow: auto;
  height: 500px;
}



.right {
  height: 500px;
  width: 100%;
  border-radius: 8px;
  overflow: auto;
}



.leftContent1,
.leftContent2 {
  padding: 20px 0px;

  h2 {
    font-size: 13px;
    margin-top: 28px;
    margin-bottom: 15px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    color: var(--gray-1, #333);
  }
}

.leftContent1 {
  border-bottom: 1px solid #e6e6e6;
}



.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f7ff;
  padding: 0px 26px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
}

.name {
  padding: 10px;

  p {
    font-size: 16px;
    font-weight: 900;
    color: var(--gray-1, #333);
  }

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    text-transform: uppercase;
    color: #6a451a
  }
}


.viewCarePlanBtn {
  align-self: flex-end;

  button {
    border: none;
    color: var(--dark-brown, #6a451a);
    font-size: 14px;
    font-weight: 900;
    background: none;
    cursor: pointer;
    text-decoration-line: underline;
  }
}