.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  height: 81vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  height: 81vh;
  margin-left: auto;
  margin-right: auto;
}

.topName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .image {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      border-radius: 50%;
    }

    h1 {
      color: var(--gray-1, #333);
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 2px;
    }

    span {
      color: var(--gray-3, #828282);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.container {
  .title {
    margin-top: 30px;
    margin-bottom: 27px;

    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--mobile-primary-color-brown, #b17f34);
    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-1, #333);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 51px 73px;
    justify-content: center;
    background: #fff;

    .contentText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        text-align: center;
        font-size: 20px;
        font-weight: 800;
        line-height: 27px;
        color: var(--gray-1, #333);
      }

      p {
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        width: 70%;
        margin-top: 16px;
        color: var(--gray-1, #333);
      }
    }
  }
}

.contentOptions {
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 3.5rem;
  padding: 10px;
}

.done {
  align-self: flex-end;

  button {
    height: 48px;
    flex-shrink: 0;
    width: 143px;
    background: var(--mobile-primary-color-default-color, #fd9c42);
    border: none;
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    color: #fff;
    margin-top: 49px;
    cursor: pointer;
  }
}