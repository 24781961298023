.tableContainer {
  background-color: #fff;
  padding: 63px 42px;
  margin-top: 27px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  border-radius: 8px;
  font-family: 'Avenir';
}

table {
  width: 100%;
}

.tableHeader {
  font-size: 12px;
  font-weight: 800;
  font-family: 'Avenir-black';
  color: var(--dark-brown, #6a451a);
}

.table-details-button {
  display: inline-flex;
  height: 40px;
  border-radius: 6px;
  border: none;
  background: var(--mobile-primary-color-brown, #b17f34);
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
  padding: 8px 16px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Avenir';
}

.tableRow {
  td {
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Avenir';
    color: var(--gray-2, #4f4f4f);
  }
  .progress,
  .success,
  .failed {
    font-size: 13px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: 'Avenir';
  }
  .progress {
    color: #b17f34;
  }
  .success {
    color: var(--green-2, #27ae60);
  }
  .failed {
    color: var(--red, #eb5757);
  }
}

.arrowImage {
  cursor: pointer;
  transition: 0.4s;
}
.arrowImage:hover {
  opacity: 0.4;
}
.btn {
  padding: 10px;
  border-radius: 5px;
  background-color: #fd9c42;
  border: none;
  font-family: 'Avenir';

}
