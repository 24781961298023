.root {
  height: 480px;
  flex-shrink: 0;
  width: 720px;
  padding: 39px 44px;
  overflow-y: scroll;
  overflow-x: hidden;
  .header {
    margin-bottom: 40px;
    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--gray-1, #333);
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-2, #4f4f4f);
    }
  }
}

.root::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

.root::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Change the color as needed */
  border-radius: 4px;
}

.root::-webkit-scrollbar-track {
  background-color: transparent;
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 4px;
  }
  span {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}

.label {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}

.homework {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .inputField {
    display: flex;
    flex-direction: column;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
  input {
    height: 60px;
    width: 320px;
    border: none;
    background: var(--gray-6, #f2f2f2);
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
  }
  input:focus {
    outline: none;
  }
}

.dropdown {
  margin-top: 0;
  width: 275px;
}

.homeworkHeader {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
  .delete {
    right: 0;
    font-size: 14px;
    cursor: pointer;
    font-weight: 900;
    color: #eb5757;
    top: 0;
  }
}

.homeworkRoot {
  display: flex;
  margin-bottom: 35px;
  flex-direction: column;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.done {
  color: #fff;
  background-color: #fd9c42;
  width: 196px;
  height: 55px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
}
.duration {
  width: 40%;
}
