.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;
  margin-left: auto;
  margin-right: auto;
}

.topName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      border-radius: 50%;
    }
    h1 {
      color: var(--gray-1, #333);
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 2px;
    }
    span {
      color: var(--gray-3, #828282);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.header {
  margin-top: 30px;
  margin-bottom: 27px;
  h1 {
    font-size: 20px;
    font-weight: 900;
    color: var(--mobile-primary-color-brown, #b17f34);
  }
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}
.homework {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-bottom: 30px;
  overflow: auto;
}

.title {
  padding: 18px 41px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 18px;
    font-weight: 900;
    line-height: 27px;
    color: var(--gray-2, #4f4f4f);
  }
}
.editButton {
  color: var(--dark-brown, #6a451a);
  font-size: 16px;
  font-weight: 900;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
  button {
    background: #fee1c7;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin-top: 25px;
    height: 48px;
    width: 182px;
  }
}

.bottom {
  margin-top: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .orders {
    width: 504px;
    height: 329px;
    background: #fff;
    overflow: auto;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    border-radius: 8px;
  }
}

.tasks {
  display: flex;
  flex-direction: row;
  padding: 30px;
  height: 250px;

  flex-wrap: wrap;
  background-color: #fff;
  .homework {
    margin-right: 38px;
    align-items: flex-start;
    gap: 8px;
    display: flex;
    h1 {
      font-size: 16px;
      font-weight: 900;
      color: var(--dark-brown, #6a451a);
    }
    p {
      border-radius: 4px;
      background: var(--mobile-whites-gray-white, #f2f2f2);
      padding: 5px 14px;
      width: 462px;
      height: 144px;
      overflow: auto;
    }
  }
  .homework:nth-child(even) {
    margin-right: 0;
  }
}

.tasks::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

.tasks::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Change the color as needed */
  border-radius: 4px;
}

.tasks::-webkit-scrollbar-track {
  background-color: transparent;
}

.requests {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 42px;
  .request {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 100%;
    height: 144px;
    background-color: #f2f2f2;
    overflow: auto;
    padding: 24px;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--gray-2, #4f4f4f);
    }
  }
}

.appointment {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f2f2;
  margin: 33px;
  padding: 16px 32px;
  height: 123px;
  h1 {
    color: var(--gray-1, #333);
    font-size: 18px;
    font-weight: 900;
  }
  a {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-decoration-line: underline;
    color: var(--gray-2, #4f4f4f);
  }
  p {
    font-size: 16px;
    font-weight: 900;
    margin-top: 16px;
    color: var(--dark-brown, #6a451a);
  }
}

.next {
  display: flex;
  flex-direction: row;
  float: right;
  margin-top: 48px;
  margin-bottom: 48px;
  button {
    background: var(--mobile-primary-color-default-color, #fd9c42);
    width: 181px;
    cursor: pointer;
    height: 48px;
    flex-shrink: 0;
    border: none;
    color: white;
    border-radius: 6px;
    transition: 0.3s ease-in-out;
  }
  button:hover {
    opacity: 0.5;
  }
}
