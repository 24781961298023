.wrapper {

  margin-left: auto;
  margin-right: auto;
  padding: 21px;
  flex-shrink: 0;
}

.account_body {
  .header {
    font-weight: 800;
    font-size: 20px;
    color: #6a451a;
    margin: 20px 0px;
  }
}

.container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 10px;
}

.content_wrapper {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: none;
}