.root {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  max-width: 1044px;

  min-height: 81vh;
  margin-left: auto;
  margin-right: auto;
}

.container {
  background: #fff;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  border-radius: 8px;
  width: 612px;
  padding: 39px 79px;
  .header {
    h1 {
      font-size: 20px;
      font-weight: 900;
      color: var(--gray-1, #333);
    }
    div {
      background: var(--gray-1, #333);
      border-radius: 4px;
      height: 30px;
      flex-shrink: 0;
      width: 159px;
      h2 {
        text-align: center;
        margin-top: 30px;
        font-size: 14px;
        padding-top: 4px;
        font-weight: 900;
        margin-bottom: 19px;
        line-height: 21px; /* 150% */
        color: #fff;
      }
    }
  }
}

.dropdown {
  width: 207px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contents {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.done {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  width: 57%;
  button {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    color: #fff;
    background: var(--mobile-primary-color-default-color, #fd9c42);
    height: 55px;
    width: 181px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
  }
}

.errorContainer {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  background: var(--brand-cherry-600, #db4343);
  border-radius: 4px;
  width: 612px;
  height: 112px;
  padding: 16px;
  flex-shrink: 0;
  margin-bottom: 24px;
  display: flex;
  img {
    margin-right: 12px;
  }
  h1 {
    font-size: 15px;
    font-weight: 900;
    line-height: 21px; /* 140% */
    letter-spacing: -0.12px;
    color: var(--white-100, #fff);
  }
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px; /* 161.538% */
    color: #fff;
    width: 75%;
  }
  .errorCancel {
    color: white;
    font-size: 14px;
    font-weight: 900;
    padding: 30px;
    cursor: pointer;
  }
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--brand-cherry-600, #db4343);
  border-radius: 4px;
  width: 612px;
  height: 112px;
  padding: 16px;
  flex-shrink: 0;
  margin-bottom: 24px;
  display: flex;
  img {
    margin-right: 12px;
  }
  h1 {
    font-size: 15px;
    font-weight: 900;
    line-height: 21px; /* 140% */
    letter-spacing: -0.12px;
    color: var(--white-100, #fff);
  }
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px; /* 161.538% */
    color: #fff;
    width: 75%;
  }
  .errorCancel {
    color: white;
    font-size: 14px;
    font-weight: 900;
    padding: 30px;
    cursor: pointer;
  }
}

.successContainer {
  background: var(--brand-ego-500, #1fad64);
  border-radius: 4px;
  width: 612px;
  height: 40px;
  padding: 16px;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
  p {
    color: var(--white-100, #fff);
    width: 90%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px; /* 150% */
    letter-spacing: -0.12px;
  }
  div {
    color: white;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
  }
  background: var(--brand-ego-500, #1fad64);
  border-radius: 4px;
  width: 612px;
  height: 40px;
  padding: 16px;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
  p {
    color: var(--white-100, #fff);
    width: 90%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px; /* 150% */
    letter-spacing: -0.12px;
  }
  div {
    color: white;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
  }
}

.confirmModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 346px;
  width: 364px;
  justify-content: center;
  h1 {
    text-align: center;
    font-size: 18px;
    margin-top: 14px;
    font-weight: 900;
    color: var(--gray-1, #333);
    margin-bottom: 10px;
  }
  p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 44px;
    font-weight: 400;
    width: 80%;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}

.modalButtons {
  display: flex;
  flex-direction: row;
  button:first-child {
    background: var(--mobile-primary-color-cream-yellow, #f8eedf);
    border-radius: 6px;
    text-align: center;
    width: 133px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-right: 15px;
    cursor: pointer;
    color: var(--dark-brown, #6a451a);
    padding: 10px;
    border: none;
  }
  button:last-child {
    background: var(--mobile-primary-color-cream-yellow, #fd9c42);
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    width: 133px;
    font-size: 16px;
    transition: 0.3s ease-in-out;
    font-weight: 500;
    color: var(--dark-brown, #fff);
    border: none;
  }
  button:hover {
    opacity: 0.5;
  }
  display: flex;
  flex-direction: row;
  button:first-child {
    background: var(--mobile-primary-color-cream-yellow, #f8eedf);
    border-radius: 6px;
    text-align: center;
    width: 133px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    margin-right: 15px;
    cursor: pointer;
    color: var(--dark-brown, #6a451a);
  }
  button:last-child {
    background: var(--mobile-primary-color-cream-yellow, #fd9c42);
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    width: 133px;
    font-size: 16px;
    transition: 0.3s ease-in-out;
    font-weight: 500;
    color: var(--dark-brown, #fff);
  }
  button:hover {
    opacity: 0.5;
  }
}
