:global {
  .ant-select-selector:hover {
    outline: none !important;
    border-color: #d9d9d9 !important;
  }

  .ant-select-selector {
    // background-color: green !important;
    min-height: 50px !important;
    max-height: 200px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    overflow: none !important;
    box-sizing: border-box !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    cursor: pointer !important;
  }

  .ant-select-arrow {
    margin-right: 10px;
  }

  .ant-select-selection-item {
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: left !important;
    // color: #FD9C42;
    // background-color: #FFF9F4 !important;
    margin: 0px 6px !important;
  }

  .ant-select-selection-item-remove {
    color: #FD9C42 !important;
  }
}

.custom-select-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #6a6a6a;
  position: relative;
  bottom: -13px;
  left: 10px;
  background-color: #fff;
  padding: 0px 5px;
  z-index: 3;
}