.form-action-message {
  border-radius: 5px;
  background: rgba(235, 87, 87, 0.1);
  padding: 15px;
  margin: 5px 0px;

  p {
    color: var(--red, #eb5757);
    text-align: center;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
}