.modalInputContainer {
  display: flex;
  flex-direction: column;
  label {
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px; /* 150% */
    color: var(--gray-3, #828282);
  }
  input {
    background: var(--mobile-whites-gray-white, #f2f2f2);
    border-radius: 5px;
    height: 55px;
    padding: 16px;
    border: none;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
    color: var(--gray-2, #4f4f4f);
  }
  input:focus {
    outline: none;
  }
}
