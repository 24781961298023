.dashboard-layout-container {
  background: #F2F2F2;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  font-weight: 800;

}

.dashboard-layout-inner-wrapper {
  overflow: hidden;
  padding: 0px 70px;
  width: 100%
}

.dashboard-layout-children {
  width: 100%;
  font-family: 'Avenir';
  font-weight: 900;
  max-width: 1700px;
  margin: 0 auto;
}