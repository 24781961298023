.navbar-root {
  background: #f2f2f2;
  padding: 2px 70px;
  border-bottom: 1px solid #e1e1e1;
}

.navbar-container {
  display: flex;
  padding: 1px;
  max-width: 1565px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;


  .navbar-nav-items {
    display: flex;
    justify-content: space-evenly;
    padding: 5px 15px;
    align-items: center;
  }

  .navbar-notification {
    .notification-icon {
      border-radius: 10px;
      background: #fff;
      padding: 6px;
      margin-right: 5px;
    }

    display: flex;
    align-items: center;

    .navbar-profile-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      position: relative;

      width: 150px;

      border-radius: 10px;
      padding: 10px;

      img {
        cursor: pointer;
      }

      small {
        color: #0a1629;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
      }
    }
  }

  .navbar-profile {
    transition: all 0.3s ease-in-out;
    margin-top: 15px;
    background-color: #fff;
    position: absolute;
    border-radius: 10px;
    padding: 10px;
    width: 150px;


    h6 {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      padding: 10px;
      color: #414141;
      cursor: pointer;
    }

    .profile {
      color: #412652;
      font-size: 15px;
      font-family: "Avenir";
      font-weight: bold;
      text-decoration: none;
    }

    .separator {
      width: 100%;
      border: none;
      border-top: 1px solid #333;
    }
  }
}


.navbar-profile-container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}