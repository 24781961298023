.root {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: flex-start;
  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    color: #6a6a6a;
  }
  input {
    height: 45px;
    flex-shrink: 0;
    width: 207px;
    margin-top: 4px;
    background: var(--gray-6, #f2f2f2);
    border-radius: 5px;
    border: none;
  }
  input:focus {
    outline: none;
  }
}
