.root {
  height: 472px;
  width: 397px;
  padding: 10px 36px;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    color: var(--gray-1, #333);
    margin-top: 0;
    margin-bottom: 25px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      color: var(--gray-2, #4f4f4f);
    }
    textarea {
      background: var(--mobile-whites-gray-white, #f2f2f2);
      border-radius: 5px;
      border: none;
      padding: 16px;
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      line-height: 21px;
      color: var(--gray-2, #4f4f4f);
    }
    textarea:focus {
      outline: none;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: var(--gray-2, #4f4f4f);
    }
    button {
      width: 100%;
      background: var(--mobile-primary-color-default-color, #fd9c42);
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 900;
      border-radius: 6px;
      cursor: pointer;
      border: none;
      height: 50px;
      margin-top: 35px;
      transition: 0.4s;
    }
    button:hover {
      opacity: 0.7;
    }
  }
}

.exceeded {
  color: red !important;
}
