/* Common styles for .nav-item-container */
.nav-item-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  width: max-content;
  margin-right: 8px;
  background: #f2f2f2;
}

/* Styles for .nav-item-container on hover */
.nav-item-container:hover,
.isActive {
  background: #f4e5d8;
  transition: 0.4s;

  h6 {
    color: #b17f34 !important;
  }

  .icon-svg {
    fill: #b17f34 !important;
  }
}

.isActive {
  h6 {
    color: #b17f34 !important;
    font-weight: 900 !important;
  }

  .icon-svg {
    fill: #b17f34 !important;
  }
}



.nav-item-container img {
  margin-right: 10px;
}

.nav-item-container h6 {
  color: #414141;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.nav-link,
.not-allowed-nav-link {
  text-decoration: none;
}

.not-allowed-nav-link {
  cursor: not-allowed;
}