.onboarding-layout {
  width: 100%;
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header {
  padding: 0px 55px;
  margin-top: 20px;

  img {
    width: 6%;
  }

}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.terms_footer {
  height: 56px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}