/* Styling the radio container */
.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray-1, #333);
  cursor: pointer;
}

/* Hide the default radio input */
.custom-radio {
  display: none;
}

/* Styling the radio indicator (the circle) */
.radio-indicator {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #fd9c42;
  /* Initial border color */
  border-radius: 50%;
  /* Make it a circle */
  margin-right: 8px;
  vertical-align: middle;
  transition: border-width 0.3s, border-color 0.3s;
  /* Add smooth transition */
}

/* Styling the radio indicator when selected */
.custom-radio:checked+.radio-indicator {
  border-width: 6px;
  /* Increase border thickness when selected */
  border-color: #fd9c42;
  /* Change border color when selected */
}